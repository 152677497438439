export const ROUTES = {
  flights: "/api/flights",
  "flights-details": "/api/flight/",
  initialize: "/api/initialize",
  settings: "/api/settings",
  subscribe: "/api/subscribe",
  unsubscribe: "/api/unsubscribe",
  favorites: "/api/favorites",
  favorite: "/api/favorite",
  unfavorite: "/api/unfavorite",
  analytics: "/api/analytics",
};
