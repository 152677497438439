// prettier-ignore
import store, { persistor } from "./redux/store";
//The import above must be at the top in order for RTK baseAPI to work
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "routes/Router";

import ErrorBoundary from "./components/ErrorBoundary";
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";
import PocketFlightsThemeProvider from "./components/ThemeProvider";

import "react-toastify/dist/ReactToastify.css";
//Overrides react-toastify styling
import "./assets/styles/custom-toastify-styles.css";
import ToastContainer from "components/ToastContainer";

const App = () => {
  return (
    <PersistGate loading={true} persistor={persistor}>
      <Provider store={store}>
        <PocketFlightsThemeProvider>
          <ErrorBoundary fallback={ErrorBoundaryFallback}>
            <ToastContainer
              draggablePercent={40}
              draggableDirection="y"
              autoClose={1300}
              closeButton={false}
              hideProgressBar={true}
            />
            <Router />
          </ErrorBoundary>
        </PocketFlightsThemeProvider>
      </Provider>
    </PersistGate>
  );
};

export default App;
