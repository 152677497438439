import { Box, Text } from "components";

export default function ArrivalsInfo({ belt, terminal }) {
  return (
    <>
      <Box flex="1" d="flex" direction="column" gap="xxs">
        <Text color="textPrimary"> Baggage Belt </Text>
        <Text fontSize="m" color="otherText">
          {belt}
        </Text>
      </Box>
      <Box flex="1" d="flex" direction="column" gap="xxs" bL>
        <Text color="textPrimary" mL="m">
          Terminal
        </Text>
        <Text fontSize="m" color="otherText" mL="m">
          {terminal}
        </Text>
      </Box>
    </>
  );
}
