import { Box, Text } from "components";

export default function DeparturesInfo({
  hideCheckin = false,
  checkin,
  gate,
  terminalName,
}) {
  return (
    <>
      {hideCheckin ? null : (
        <Box
          flex="1"
          d="flex"
          direction="column"
          gap="xxs"
          justifyContent="space-between"
          mR="xxs"
        >
          <Text color="textPrimary"> Check-in Counters </Text>
          <Text fontSize="m" color="otherText">
            {checkin}
          </Text>
        </Box>
      )}
      <Box
        flex="1"
        d="flex"
        direction="column"
        gap="xxs"
        justifyContent="space-between"
        bL={hideCheckin ? null : true}
        mR="xxs"
      >
        <Text color="textPrimary" mL={hideCheckin ? null : "m"}>
          Gate
        </Text>
        <Text fontSize="m" color="otherText" mL={hideCheckin ? null : "m"}>
          {gate}
        </Text>
      </Box>
      <Box
        flex="1"
        d="flex"
        direction="column"
        gap="xxs"
        justifyContent="space-between"
        bL
        mR="xxs"
      >
        <Text color="textPrimary" mL="m">
          Terminal
        </Text>
        <Text fontSize="m" color="otherText" mL="m">
          {terminalName}
        </Text>
      </Box>
    </>
  );
}
