import React from "react";
import { Box, Text } from "components";

//DOM computed height
export const FLIGHTS_COUNT_HEIGHT = 26.19;

const FlightsCount = ({ flightsCount, noTerminalFlights }) => {
  const flightPlural = flightsCount > 1 ? " Flights" : " Flight";
  return (
    <Box pL="m" pB="xs" backgroundColor="backgroundTertiary">
      <Text color="text">
        {flightsCount}
        {flightPlural}
        {noTerminalFlights ? " in other terminals" : undefined}
      </Text>
    </Box>
  );
};

export default FlightsCount;
