import React from "react";
import { Tab, Tabs } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  flightsListSelector,
  setActiveTab,
  triggerScrollToTop,
} from "redux/slices/flightsList.slice";
import styled from "styled-components";

//DOM computed height
export const FLIGHT_TABS_HEIGHT = 109.79;

const FlightTabs = ({ showFavoriteTab, backToTopOffset }) => {
  const { activeTab } = useSelector(flightsListSelector);
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    dispatch(triggerScrollToTop(tab === "favorite" ? 0 : backToTopOffset));
    dispatch(setActiveTab(tab));
  };

  return (
    <FlightTabsStyled id="tabs">
      <Tabs
        activeTab={activeTab}
        initialActive={activeTab}
        withPanels={false}
        onTabClick={handleTabClick}
      >
        {showFavoriteTab && <Tab title="Favorites" value="favorite"></Tab>}
        <Tab title="All Flights" value="all"></Tab>
        <Tab title="Departures" value="departure"></Tab>
        <Tab title="Arrivals" value="arrival"></Tab>
      </Tabs>
    </FlightTabsStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const FlightTabsStyled = styled.div`
  padding-top: 46px;
  padding-bottom: 10px;
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
`;

export default FlightTabs;
