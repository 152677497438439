import {
  ACTIVE_TAB,
  FLIGHT_TYPE,
  START_SEARCH_CONTAIN_LENGTH,
} from "configs/constants";

export const filterFlightsBySearch = (searchKeyword, allFlights, options) => {
  const { flights, favoriteFlights } = allFlights;
  const {
    activeTab,
    terminalToFilter,
    startSearchContainLength = START_SEARCH_CONTAIN_LENGTH,
  } = options;

  if (activeTab === ACTIVE_TAB.FAVORITE && favoriteFlights?.length) {
    return favoriteFlights;
  }

  return flights.filter((flight) => {
    if (activeTab !== ACTIVE_TAB.ALL && flight.type !== activeTab) {
      return false;
    }

    const caseInsensitiveKeyword = searchKeyword
      .toUpperCase()
      .split(" ")
      .join(""); //remove white spaces

    const caseInsensitiveVia = flight.via_keywords?.toUpperCase();
    const caseInsensitiveAirline = flight.airline?.name
      ?.toUpperCase()
      ?.split(" ")
      ?.join("");

    const searchField =
      flight.type === FLIGHT_TYPE.DEPARTURE ? "destination" : "origin";

    const flightOfSearchField = flight[searchField]
      .toUpperCase()
      .split(" ")
      .join("");
    const airportCode = flight.airport.code.toUpperCase().split(" ").join("");
    const flightNumber = flight.flight_no.toUpperCase().split(" ").join("");

    if (caseInsensitiveKeyword.length < startSearchContainLength) {
      return (
        (flightNumber.startsWith(caseInsensitiveKeyword) ||
          flightOfSearchField.startsWith(caseInsensitiveKeyword) ||
          airportCode.startsWith(caseInsensitiveKeyword) ||
          caseInsensitiveAirline?.startsWith(caseInsensitiveKeyword)) &&
        (flight.terminal_name === terminalToFilter || terminalToFilter === null)
      );
    }

    const searchChars = caseInsensitiveKeyword.split("");
    //Store how many times the search digit is found in the flightNumber
    let history = {};

    const foundCharsInFlightNumber = searchChars.every((char) => {
      if (!history[char]) {
        history[char] = 1;
      } else {
        history[char] = history[char] + 1;
      }

      const countInFlightNumber = (
        flightNumber.match(new RegExp(char, "g")) || []
      ).length;
      return countInFlightNumber >= history[char];
    });

    return (
      foundCharsInFlightNumber ||
      ((flightOfSearchField.includes(caseInsensitiveKeyword) ||
        airportCode.includes(caseInsensitiveKeyword) ||
        caseInsensitiveVia?.includes(caseInsensitiveKeyword) ||
        caseInsensitiveAirline?.includes(caseInsensitiveKeyword)) &&
        (flight.terminal_name === terminalToFilter ||
          terminalToFilter === null))
    );
  });
};
