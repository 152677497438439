import merge from "lodash/merge";

import { themes } from "./index";

const theme = themes["_base"];

const pocketFlightsTokens = {
  colors: {
    //TODO rethink all the necessary colors
    accent: "#212151",
    accent_1: "#FFB12A",

    warning: "#EDAB38",
    warning_1: "rgba(255, 177, 42, 0.1)",

    info: "#146dad",
    info_1: "rgba(20, 109, 173, 0.1)",

    success: "#09875F",
    success_1: "rgba(20, 173, 125, 0.15)",
    success_2: "rgba(20, 173, 125, 0.9)",

    danger: "#ad1414",
    danger_1: "rgba(173, 20, 20, 0.1)",

    neutral: "#494a50",
    neutral_1: "rgba(73, 74, 80, 0.1)",

    textPrimary: "#8F9098",
    textSecondary: "#71727A",
    textTertiary: "#000000",
    textQuaternary: "#FFFFFF",
    otherText: "#222222",

    backgroundTertiary: "#F2F4FA",
    backgroundQuaternary: "#FFFFFF",
    successBackground: "#E4F4C4",

    borderBottom: "#D4D6DD",

    inputBorder: "#F2F4FA",
    inputButton: "#71727A",

    headerBorder: "rgba(255, 255, 255, 0.2)",
  },
  fontSizes: {
    xl: "24px",
  },
  fontWeights: {
    bold: 700,
  },
  boxShadow: {
    dropdown: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
    flightItem: "0 0 20px rgba(0, 0, 0, 0.1)",
    searchBar: "0 0 20px rgba(0, 0, 0, 0.15)",
    ticketInfoBox: "0 0 20px rgba(0, 0, 0, 0.15)",
    modal: "0px 0px 16px rgba(0, 0, 0, 0.2)",
    scanTicketButton: "0px 4px 12px 0px rgba(0, 0, 0, 0.25);",
  },
  dropdown: {
    dropdownWidth: "73px",
    dropdownMenuWidth: "160px",
  },
  toggles: {
    colorOff: "transparent",
    borderColor: "#8F9098",
  },
  spaces: {
    paddingTopTabs: "48px",
  },
  logo: {
    pocketFlightsLoadingLogoWidth: "150px",
    pocketFlightsLogoWidth: 77,
    pocketFlightsLogoHeight: 30,
    pocketFlightsLogoFill: "#FFFFFF",
    realmLoadingLogoWidth: 134,
  },
  iconSizes: {
    l: "22px",
    xl: "24px",
  },
  tabs: {
    fontSize: "16px",
    pH: "5px",
    hoverColor: "primary",
    // activeColor: "primary",
  },
  messageBox: {
    borderWidth: "6px",
  },
  airlineLogoWidth: "80px",
  scanTicketButtonWidth: "256px",
  flightDetailsHeaderHeight: "144px",
  TicketInfoVerticalOffset: "-72px",
  noFlightIllustrationWidth: 278,
  animations: {
    highlightFlightItem: 2000,
  },
};

const pocketFlightsTheme = merge({}, theme, pocketFlightsTokens);

export default pocketFlightsTheme;
