import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, GhostButton, Icon, Text } from "components";
import { useTheme } from "styled-components";

import Menu from "./dropdown/Menu";

const Dropdown = ({
  initialValue,
  optionList,
  displayProperty = "",
  setOnChange,
  displayShortProperty = null,
  backgroundColor,
  name = "dropdown",
  w,
}) => {
  const theme = useTheme();
  const dropDownRef = useRef();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(initialValue);

  const getDropdownValue = (displayProperty, option, displayShortProperty) => {
    if (displayShortProperty) {
      return option[displayShortProperty];
    }
    if (displayProperty) {
      return option[displayProperty];
    } else {
      return option;
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        dropDownRef.current &&
        isDropDownOpen &&
        !dropDownRef.current.contains(event.target)
      ) {
        setIsDropDownOpen(false);
      }
    },
    [isDropDownOpen]
  );

  const handleArrowClick = (event) => {
    // Stop the click event propagation when clicking on the chevron arrow
    event.stopPropagation();
    setIsDropDownOpen(!isDropDownOpen);
  };

  useEffect(() => {
    setDropDownValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleDropDownItemSelect = (option) => {
    setDropDownValue(option);
    setIsDropDownOpen(false);
    setOnChange(name, option);
  };

  return (
    <Box
      w={w}
      b
      borderRadius={theme.borderRadius.base}
      borderColor={isDropDownOpen ? "primary" : "backgroundTertiary"}
      ref={dropDownRef}
      backgroundColor={backgroundColor || "backgroundTertiary"}
      position="relative"
    >
      <GhostButton
        d="flex"
        w="100%"
        gap="xxs"
        justifyContent="center"
        h="100%"
        onClick={() => {
          setIsDropDownOpen(!isDropDownOpen);
        }}
      >
        <Text color="text" fontWeight="semibold">
          {getDropdownValue(
            displayProperty,
            dropDownValue,
            displayShortProperty
          )}
        </Text>
        <Icon
          color="text"
          name={isDropDownOpen ? "ChevronUp" : "ChevronDown"}
          onClick={handleArrowClick}
        ></Icon>
      </GhostButton>
      {isDropDownOpen && (
        <Menu
          getDropdownValue={getDropdownValue}
          handleDropDownItemSelect={handleDropDownItemSelect}
          displayProperty={displayProperty}
          dropDownValue={dropDownValue}
          isDropDownOpen={isDropDownOpen}
          optionList={optionList}
        />
      )}
    </Box>
  );
};

export default Dropdown;
