import { hTagMixin } from "@airportlabs/smalt/src/mixins";
import { reset } from "@airportlabs/smalt/src/ThemeProvider/globalStyles/reset";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyles = createGlobalStyle`
    ${normalize};
    ${reset};

    html {
        box-sizing: border-box;
        min-height: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        /* Pocket Flights Specific global styles below */
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        overscroll-behavior-y: none;
        overscroll-behavior-x: none;
        -webkit-overscroll-behavior-y: none;
        -webkit-overscroll-behavior-x: none;
    }

    body {
        font-family: ${(props) => props.theme.fontFamily.base.name};
        font-size: ${(props) => props.theme.fontSizes.base};
        line-height: ${(props) => props.theme.lineHeights.base};
        color: ${(props) => props.theme.colors.text};
        font-weight: ${(props) => props.theme.fontWeights.normal};
        
        /* Pocket Flights Specific global styles below */

        width: 100%;
        height: 100vh;
        margin: 0;
        overscroll-behavior: none;
        background-color: ${(props) => props.theme.colors.backgroundTertiary};
    }

    * {
        &,
        &:before,
        &:after {
            box-sizing: inherit;
        }
    }

    address,
    blockquote,
    p,
    pre,
    dl,
    ol,
    ul,
    figure,
    hr,
    table,
    fieldset {
        margin-bottom: ${(props) => props.theme.spaces.base};
    }

    dd,
    ol,
    ul {
        margin-left: ${(props) => props.theme.spaces.base};
    }

    img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    h1 {
        ${hTagMixin("h1")}
    }
    h2 {
        ${hTagMixin("h2")}
    }
    h3 {
        ${hTagMixin("h3")}
    }
    h4 {
        ${hTagMixin("h4")}
    }
    h5 {
        ${hTagMixin("h5")}
    }
    h6 {
        ${hTagMixin("h6")}
    }

    p {
        margin-bottom: ${(props) => props.theme.spaces.base};
    }

    hr {
        display: block;
        width: 100%;
        border: 0;
        border-top: ${(props) => props.theme.border};
        padding: 0;
        margin: ${(props) => props.theme.spaces.base} auto;
    }

    strong,
    b {
        font-weight: ${(props) => props.theme.fontWeights.bold};
    }

    button {
        cursor: pointer;
    }

    .ReactModal__Body--open {
        overflow: hidden;
    }
`;

export default GlobalStyles;
