import React from "react";
import { Box, Text } from "components";
import { DateTime } from "luxon";

const Title = () => {
  return (
    <Box pV="xxs" pH="m" pB="xl" pT="xxs">
      <Text mV="xs" fontSize="h1" color="textQuaternary">
        What flight are you looking for?
      </Text>
      <Text fontWeight="normal" fontSize="m" mB="l">
        {DateTime.now().toFormat("cccc, d LLL y")}
      </Text>
    </Box>
  );
};

export default Title;
