import React from "react";
import { Box, SecondaryButton, Text } from "components";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import useWindowDimensions from "hooks/windowDimensions";
import { QRCode } from "react-qrcode-logo";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import { formatQueryString } from "utils/formatQueryStrings";

const TerminalQRs = () => {
  useTrackPageAnalytics({
    name: "Terminals QRs Page",
  });
  const { width } = useWindowDimensions();
  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnReconnect: true,
  });

  const { terminals: terminalQRs = [] } = settingsData;
  const QrLogo = settingsData.theme?.qr_logo;

  const downloadQRCode = (terminalId, terminalTitle) => {
    const canvas = document.getElementById(terminalId);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${terminalTitle}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Box
      d="flex"
      direction="column"
      gap="xxl"
      justifyContent="center"
      alignItems="center"
      mV="xxl"
      pB="xxl"
    >
      {terminalQRs.map((terminalQR) => {
        const id = terminalQR.full_name;
        const terminalTitle = terminalQR.full_name;
        const terminalNumber = terminalQR.value;

        return (
          <Box backgroundColor="primary" borderRadius="l" p="l" key={id}>
            <Text
              fontWeight="semibold"
              color="textQuaternary"
              textAlign="center"
              m="s"
              fontSize="xl"
            >
              {terminalTitle}
            </Text>
            <QRCode
              id={id}
              size={Math.min(width / 2, 400)}
              value={
                window.location.origin +
                formatQueryString({ terminal: terminalNumber })
              }
              eyeRadius={[
                {
                  outer: 30,
                  inner: 0,
                },
                {
                  outer: 30,
                  inner: 0,
                },
                {
                  outer: 30,
                  inner: 0,
                },
              ]}
              enableCORS={true}
              quietZone={width / 50}
              logoWidth={Math.min(width / 8, 70)}
              logoHeight={Math.min(width / 8, 70)}
              logoImage={QrLogo}
            />
            <Box d="flex" justifyContent="center" mT="l">
              <SecondaryButton
                onClick={() => downloadQRCode(id, terminalTitle)}
              >
                <Text fontSize="l" fontWeight="semibold">
                  Download
                </Text>
              </SecondaryButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default TerminalQRs;
