import { ROUTES } from "api";

import { getClientCredentials } from "./auth.service";

export const addFlightToFavorites = async (flightId) => {
  const { uniqueDeviceIdentifier, clientId, clientSecret } =
    await getClientCredentials();

  const data = JSON.stringify({ device_id: uniqueDeviceIdentifier, flightId });

  try {
    const response = await fetch(
      `${window._env_.REACT_APP_API_URL}${ROUTES.favorite}`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          "shared-key": window._env_.REACT_APP_SHARED_KEY,
          "client-id": clientId,
          "client-secret": clientSecret,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Could not favorite flight: ", flightId, " Error: ", error);
  }
};

export const removeFlightFromFavorites = async (flightId) => {
  const { uniqueDeviceIdentifier, clientId, clientSecret } =
    await getClientCredentials();

  const data = JSON.stringify({ device_id: uniqueDeviceIdentifier, flightId });

  try {
    const response = await fetch(
      `${window._env_.REACT_APP_API_URL}${ROUTES.unfavorite}`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          "shared-key": window._env_.REACT_APP_SHARED_KEY,
          "client-id": clientId,
          "client-secret": clientSecret,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Could not unfavorite flight: ", flightId, " Error: ", error);
  }
};
