import React from "react";
import { ReactComponent as PocketFlightsLogo } from "assets/images/pocket-flights-logo.svg";
import { Box } from "components";
import { useTheme } from "styled-components";

const Logos = ({ logoURL }) => {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      pH="l"
      pV="s"
      bB
      borderColor="headerBorder"
    >
      <Box>
        {logoURL && (
          <img
            width={theme.logo.realmLogoWidth}
            src={logoURL}
            alt="realm-logo"
          />
        )}
      </Box>
      <PocketFlightsLogo
        width={
          theme.logo.pocketFlightsLogoWidth || theme.logo.pocketFlightsWidth
        }
        height={
          theme.logo.pocketFlightsLogoHeight || theme.logo.pocketFlightsHeight
        }
        fill={theme.logo.pocketFlightsLogoFill || theme.colors.primary}
      />
    </Box>
  );
};

export default Logos;
