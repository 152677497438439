import React, { useCallback, useMemo } from "react";
import { Search } from "components";
import Dropdown from "components/Reusable/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  flightsListSelector,
  setSearchKeyword,
  triggerScrollToTop,
} from "redux/slices/flightsList.slice";
import styled, { useTheme } from "styled-components";

import { FLIGHTS_COUNT_HEIGHT } from "../FlightsCount";
import { FLIGHT_TABS_HEIGHT } from "./FlightTabs";

const SEARCH_BOX_HEIGHT = 88;

const SearchBox = ({
  terminal,
  terminalsOptionList,
  setQueryParams,
  isFlightCountVisible,
  backToTopOffset,
}) => {
  const { data: settingsData = {} } = useGetSettingsQuery();
  const isTerminalFilteringActive = settingsData.terminalFilter !== "disabled";

  const currentUrlTerminal = terminalsOptionList.find((apiTerminal) => {
    return apiTerminal.value === terminal;
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchKeyword } = useSelector(flightsListSelector);

  const handleSearch = (keyword) => {
    dispatch(setSearchKeyword(keyword));
  };

  const TerminalDropdownOptionList = useMemo(
    () => [
      {
        full_name: "All Terminals",
        short_name: "All",
        value: null,
      },
      ...terminalsOptionList,
    ],
    [terminalsOptionList]
  );

  const onDropdownChange = useCallback(
    (name, option) => {
      if (!option.value) {
        setQueryParams();
        return;
      }
      setQueryParams({ terminal: option.value });
      dispatch(triggerScrollToTop(backToTopOffset));
    },
    [dispatch, setQueryParams, backToTopOffset]
  );

  return (
    <SearchBoxStyled isFlightCountVisible={isFlightCountVisible}>
      <Search
        onSearch={(value) => {
          handleSearch(value);
        }}
        placeholder="Flight number or city"
        autoComplete="off"
        searchOnChange
        debounce={200}
        backgroundColor="backgroundTertiary"
        value={searchKeyword}
      />
      {isTerminalFilteringActive && (
        <Dropdown
          displayProperty="full_name"
          displayShortProperty="short_name"
          optionList={TerminalDropdownOptionList}
          initialValue={currentUrlTerminal || TerminalDropdownOptionList[0]}
          w={theme.dropdown.dropdownWidth}
          setOnChange={onDropdownChange}
        />
      )}
    </SearchBoxStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const SearchBoxStyled = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  gap: ${(props) => props.theme.spaces.s};
  background-color: ${(props) => props.theme.colors.textQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  padding: ${(props) => props.theme.spaces.m} ${(props) => props.theme.spaces.m};

  left: ${(props) => props.theme.spaces.m};
  width: calc(100vw - ${(props) => props.theme.spaces.xxl});

  top: calc(
    100% -
      ${(props) =>
        props.isFlightCountVisible
          ? FLIGHT_TABS_HEIGHT + SEARCH_BOX_HEIGHT / 2 + FLIGHTS_COUNT_HEIGHT
          : FLIGHT_TABS_HEIGHT + SEARCH_BOX_HEIGHT / 2}px
  );
`;

export default SearchBox;
