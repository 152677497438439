import React from "react";
import { ReactComponent as InfoIcon } from "assets/images/info.svg";
import { Box, Text } from "components";

const TryAgainModalContent = ({
  setIsScanUnsuccessful,
  setIsTryAgainModalOpen,
}) => {
  return (
    <Box
      mV="m"
      d="flex"
      direction="column"
      gap="m"
      justifyContent="center"
      alignItems="center"
    >
      <Box d="flex" gap="s" alignItems="center">
        <InfoIcon />
        <Text fontSize="l" color="otherText">
          Flight not found
        </Text>
      </Box>
      <Text color="textSecondary" fontSize="m" mH="l" textAlign="center">
        The scan was unsuccessful. Please try again or search manually for your
        flight.
      </Text>
      <Box
        onClick={() => {
          setIsScanUnsuccessful(false);
          setIsTryAgainModalOpen(false);
        }}
      >
        <Text fontSize="h1" color="primary">
          Try again
        </Text>
      </Box>
    </Box>
  );
};

export default TryAgainModalContent;
