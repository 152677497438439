import { Box, Text } from "components";
import Toggle from "components/smaltOverwritten/Toggle";
import { NOTIFICATION_CASES } from "configs/constants";
import { useSelector } from "react-redux";
import { notificationsSelector } from "redux/slices/notifications.slice";
import {
  subscribeToNotificationsServer,
  unsubscribeNotificationForFlight,
} from "services/notifications.service";
import styled from "styled-components";

const NotificationsContentStyled = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spaces.s};
  flex-direction: column;
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const NotificationInstructions = ({
  flightId,
  handleOpenNotificationsModal,
  notificationType,
}) => {
  const { notificationFlights } = useSelector(notificationsSelector);

  const hasNotificationsEnabledForFlight =
    notificationFlights.includes(flightId);

  let informationText = "";
  let toggleDisabled = false;

  switch (notificationType) {
    case NOTIFICATION_CASES.IOS_VERSION_BELOW_16_4:
      informationText =
        "Please update your operating system to version 16.4 or above to receive push notifications. Older OS versions do not support this feature.";
      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.DEFAULT_BROWSER_NOT_SAFARI:
      informationText =
        "Push notifications are only supported on Safari browser. Please switch to Safari as your default browser to enable this feature.";
      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.APP_NOT_INSTALLED_AS_PWA:
      informationText =
        "Stay informed throughout your flight with our exclusive flight notifications. Follow these simple steps to enable notifications on your iOS device.";
      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA:
    case NOTIFICATION_CASES.USER_IS_NOT_BROWSING_MOBILE:
      informationText =
        "Stay informed throughout your flight with our exclusive flight notifications, ensuring you receive timely updates without any alerts extending beyond your journey.";
      break;
    default:
      break;
  }

  const handleToggleChange = () => {
    if (hasNotificationsEnabledForFlight) {
      unsubscribeNotificationForFlight(flightId);
    } else {
      subscribeToNotificationsServer(flightId);
    }
  };

  return (
    <NotificationsContentStyled>
      <Box d="flex" justifyContent="space-between">
        <Text fontSize="m" color="otherText">
          Receive flight notifications
        </Text>
        <Toggle
          onChange={
            toggleDisabled
              ? () => handleOpenNotificationsModal(notificationType)
              : handleToggleChange
          }
          checked={hasNotificationsEnabledForFlight}
          name="push-notifications"
          onClick={
            toggleDisabled
              ? () => handleOpenNotificationsModal(notificationType)
              : null
          }
          size="big"
          borderColor="borderBottom"
        />
      </Box>
      <Box>
        <Text color="textSecondary">
          {informationText}
          &nbsp;
          <Text
            onClick={() => handleOpenNotificationsModal(notificationType)}
            color="primary"
            as="span"
          >
            Learn More
          </Text>
        </Text>
      </Box>
    </NotificationsContentStyled>
  );
};

export default NotificationInstructions;
