import React from "react";
import { ReactComponent as CircleVia } from "assets/images/circle-via.svg";
import { Box, Icon, Text } from "components";
import styled, { useTheme } from "styled-components";

const SHORTENED_TEXT_THRESHOLD = 24;

const ViaContainerStyled = styled.div`
  display: flex;
  margin-left: 5px;
  margin-top: -3px;
`;
const ViaTextStyled = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 14px;
`;

const Content = ({ type, via, destination, origin, number }) => {
  const theme = useTheme();
  return (
    <Box mH="s" mB="s">
      <Box d="flex" justifyContent="space-between" alignItems="center">
        <Box d="flex">
          <Icon
            color="primary"
            name={type === "departure" ? "FlightTakeoff" : "FlightLand"}
            size="l"
          />
          <Text
            mL="xs"
            fontSize={
              destination?.length > SHORTENED_TEXT_THRESHOLD ||
              origin?.length > SHORTENED_TEXT_THRESHOLD
                ? "base"
                : "l"
            }
            fontWeight="semibold"
          >
            {type === "departure" ? destination : origin}
          </Text>
        </Box>
        <Text fontWeight="base" fontSize="l">
          {number}
        </Text>
      </Box>
      {via && (
        <ViaContainerStyled>
          <CircleVia stroke={theme.colors.primary} />
          <ViaTextStyled>
            {via.length > 1 ? `${via.length} stops` : `via ${via[0]?.name}`}
          </ViaTextStyled>
        </ViaContainerStyled>
      )}
    </Box>
  );
};

export default Content;
