import React from "react";
import { Box, Text } from "components";
import styled from "styled-components";

const CustomCheckinStyled = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const CustomCheckin = ({ checkins = [] }) => {
  return (
    <CustomCheckinStyled>
      <Box w="100%" d="flex" direction="column" justifyContent="center">
        <Box d="flex" justifyContent="space-between" mB="base">
          <Text color="textPrimary">Check-in Counters</Text>
          <Text color="textPrimary">Area</Text>
        </Box>
        <Box w="100%" d="flex" direction="column" gap="s">
          {checkins.map((checkIn) => {
            const areas = checkIn.areas || [];
            return (
              <Box key={checkIn.name} d="flex" alignItems="center">
                <Box flex="1.8">
                  <Text>{checkIn?.name}</Text>
                </Box>
                <Box d="flex" justifyContent="flex-end" flex="1">
                  {areas?.map((area, index) => (
                    <Text key={area.name} textAlign="right" fontSize="l">
                      {area.name}
                      {index !== areas.length - 1 && ","}
                    </Text>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CustomCheckinStyled>
  );
};

export default CustomCheckin;
