import React, { useState } from "react";
import { ReactComponent as ScanTicketIcon } from "assets/images/scan-ticket.svg";
import { Modal, Text } from "components";
import { ACTIVE_TAB, START_SEARCH_CONTAIN_LENGTH } from "configs/constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedFlightDetails } from "redux/slices/flightsList.slice";
import styled from "styled-components";
import { filterFlightsBySearch } from "utils/flightList.util";

import CameraPermissionModalContent from "./ticketScanner/CameraPermissionModalContent";
import TicketScannerModalContent from "./ticketScanner/TicketScannerModalContent";

const FixedButtonContainerStyled = styled.div`
  position: fixed;
  width: ${(props) => props.theme.scanTicketButtonWidth};
  height: ${(props) => props.theme.spaces.xxl};
  border-radius: 64px;
  box-shadow: ${(props) => props.theme.boxShadow.scanTicketButton};
  background-color: ${(props) => props.theme.colors.primary};
  bottom: ${(props) => props.theme.spaces.m};
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spaces.xs};
`;

const TicketScanner = ({ flights }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isCameraPermissionModalOpen, setIsCameraPermissionModalOpen] =
    useState(false);
  const [isScanUnsuccessful, setIsScanUnsuccessful] = useState(false);

  const handleScanTicketClick = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setIsVideoModalOpen(true);
    } catch (error) {
      setIsCameraPermissionModalOpen(true);
    }
  };

  const handleGetTicketNumber = (ticketNumber) => {
    const searchForScannedTicket = filterFlightsBySearch(
      ticketNumber,
      { flights, favoriteFlights: null },
      {
        activeTab: ACTIVE_TAB.ALL,
        terminalToFilter: null,
        startSearchContainLength: START_SEARCH_CONTAIN_LENGTH,
      }
    );

    if (searchForScannedTicket.length > 2 || !searchForScannedTicket.length) {
      setIsScanUnsuccessful(true);
      return;
    }

    if (searchForScannedTicket.length === 2) {
      //If two flights are found, the departure takes higher priority
      const foundFlight = searchForScannedTicket.find(
        (flight) => flight.type === "departure"
      );
      dispatch(setSelectedFlightDetails(foundFlight));
      navigate(`/flight/${foundFlight.ref_id}`, {
        state: {
          hasScannedTicket: true,
        },
      });

      return;
    }

    const foundFlight = searchForScannedTicket[0];
    dispatch(setSelectedFlightDetails(foundFlight));
    navigate(`/flight/${foundFlight.ref_id}`, {
      state: {
        hasScannedTicket: true,
      },
    });
  };

  return (
    <>
      {isVideoModalOpen && (
        <TicketScannerModalContent
          setIsVideoModalOpen={setIsVideoModalOpen}
          isScanUnsuccessful={isScanUnsuccessful}
          setIsScanUnsuccessful={setIsScanUnsuccessful}
          handleGetTicketNumber={handleGetTicketNumber}
        />
      )}
      <Modal
        isOpen={isCameraPermissionModalOpen}
        onRequestClose={() => setIsCameraPermissionModalOpen(false)}
        withCloseButton={false}
        w="90%"
        verticalPosition="center"
      >
        <CameraPermissionModalContent
          handleCloseModal={setIsCameraPermissionModalOpen}
        />
      </Modal>
      <FixedButtonContainerStyled onClick={handleScanTicketClick}>
        <ScanTicketIcon />
        <Text fontWeight="semibold" color="textQuaternary">
          Scan your ticket
        </Text>
      </FixedButtonContainerStyled>
    </>
  );
};

export default TicketScanner;
