import React from "react";
import { Text } from "components";
import { DateTime } from "luxon";

const LastUpdate = ({ lastUpdated }) => {
  const relativeTimeOfLastUpdate = DateTime.fromISO(lastUpdated).toRelative();

  if (!relativeTimeOfLastUpdate) {
    return null;
  }

  return (
    <Text mB="l" color="textSecondary">
      Updated {relativeTimeOfLastUpdate}
    </Text>
  );
};

export default LastUpdate;
