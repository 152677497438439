import React from "react";
import { Box, Icon, Text } from "components";
import { DateTime } from "luxon";
import Status from "pages/flightList/flightsContainer/flightItem/header/Status";

const TimeInformation = ({ type, flightTime, newTime, status }) => {
  return (
    <Box d="flex" justifyContent="space-between">
      <Box d="flex" direction="column" gap="s">
        <Text color="textPrimary" fontWeight="semibold">
          {type === "departure" ? "Departing at" : "Arriving at"}
        </Text>
        <Box d="flex" alignItems="center" gap="xxs">
          <Text
            textDecoration={newTime && "line-through"}
            color="textTertiary"
            fontSize="xl"
            fontWeight="bold"
          >
            {DateTime.fromISO(flightTime).toFormat("HH:mm")}
          </Text>
          {newTime && (
            <Box d="flex" gap="xxs" alignItems="center" justifyContent="center">
              <Icon color="accent_1" size="xl" name="ArrowRight" />
              <Text color="accent_1" fontSize="xl" fontWeight="bold">
                {DateTime.fromISO(newTime).toFormat("HH:mm")}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box d="flex" direction="column" gap="s">
        <Text color="textPrimary" fontWeight="semibold">
          Flight Status
        </Text>
        <Status status={status} />
      </Box>
    </Box>
  );
};

export default TimeInformation;
