import React from "react";
import { Box, Text } from "components";

const Status = ({ status }) => {
  return (
    <Box
      d="flex"
      justifyContent="center"
      pV="xxs"
      pH="xs"
      borderRadius="s"
      backgroundColor={`${status?.key}_1`}
    >
      <Text fontWeight="semibold" color={status?.key}>
        {status?.text}
      </Text>
    </Box>
  );
};

export default Status;
