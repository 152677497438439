import React from "react";
import { Box, Text } from "components";
import AirlineLogo from "components/Reusable/AirlineLogo";

const Footer = ({ airlineName, mediaUrl, gate, type, belt, terminal }) => {
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      pH="s"
      pV="xs"
      bT
    >
      <AirlineLogo airlineName={airlineName} mediaUrl={mediaUrl} />
      {type === "departure" && gate && (
        <Text fontSize="m">
          {gate?.includes("Gate") ? gate : `Gate ${gate}`}
        </Text>
      )}
      {type === "arrival" && belt && belt?.length > 0 && (
        <Text fontSize="m">Belt {belt}</Text>
      )}
      <Text fontSize="m">
        {terminal.includes("Terminal") ? terminal : `Terminal ${terminal}`}
      </Text>
    </Box>
  );
};

export default Footer;
