import { useState } from "react";
import { ReactComponent as ErrorIllustration } from "assets/images/error-illustration.svg";
import { ReactComponent as PocketFlightsLogo } from "assets/images/pocket-flights-logo.svg";
import {
  Box,
  Heading1,
  PrimaryButton,
  SecondaryButton,
  Text,
} from "components";
import { useTheme } from "styled-components";

const ErrorBoundaryFallback = ({ error, errorStack }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const theme = useTheme();

  const copyErrorToClipboard = () => {
    const errorMessage = `Error: ${error.toString()}\nStack Trace:\n${errorStack}`;

    navigator.clipboard.writeText(errorMessage).then(() => {
      setCopySuccess(true);
    });
  };

  const handleGoBackToHomepage = () => {
    window.location.href = "/";
  };

  return (
    <Box
      minH="100vh"
      d="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box flex="0.2" d="flex" justifyContent="center" alignItems="center">
        <PocketFlightsLogo
          width={
            theme.logo.pocketFlightsLogoWidth || theme.logo.pocketFlightsWidth
          }
          height={
            theme.logo.pocketFlightsLogoHeight || theme.logo.pocketFlightsHeight
          }
          fill={theme.colors.primary}
        />
      </Box>
      <Box
        flex="1"
        d="flex"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="s"
      >
        <Heading1 textAlign="center">Something went wrong</Heading1>
        <SecondaryButton onClick={copyErrorToClipboard}>
          <Text fontWeight="semibold">
            {copySuccess ? "Copied!" : "Copy Error Message"}
          </Text>
        </SecondaryButton>
        <ErrorIllustration
          style={{ marginTop: "50px", marginBottom: "20px" }}
        />
        <PrimaryButton onClick={handleGoBackToHomepage}>
          <Text fontWeight="semibold">Return to homepage</Text>
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default ErrorBoundaryFallback;
