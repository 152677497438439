import React from "react";
import { Box, Text } from "components";
import InfoIcon from "local_modules/smalt/src/Icons/components/base/Info";

const CameraPermissionModalContent = ({ handleCloseModal }) => {
  return (
    <Box
      mV="m"
      d="flex"
      direction="column"
      gap="m"
      justifyContent="center"
      alignItems="center"
    >
      <Box d="flex" gap="s" alignItems="center">
        <InfoIcon />
        <Text fontSize="l" color="otherText">
          Camera permission denied
        </Text>
      </Box>
      <Text color="textSecondary" fontSize="m" mH="l" textAlign="center">
        Go to your browser settings and enable camera permission for Pocket
        Flights.
      </Text>
      <Box onClick={() => handleCloseModal(false)}>
        <Text fontSize="h1" color="primary">
          Close
        </Text>
      </Box>
    </Box>
  );
};

export default CameraPermissionModalContent;
