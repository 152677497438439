import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock.svg";
import { ReactComponent as FootprintsIcon } from "assets/images/footprints.svg";
import { ReactComponent as HourglassIcon } from "assets/images/hourglass.svg";
import { ReactComponent as LuggageIcon } from "assets/images/luggage.svg";
import { ReactComponent as PlaneUpIcon } from "assets/images/plane-up.svg";
import { ReactComponent as ScalingIcon } from "assets/images/scaling.svg";
import { ReactComponent as UserCheckIcon } from "assets/images/user-check.svg";
import { Box, Text } from "components";
import { DateTime } from "luxon";
import styled from "styled-components";

const iconsMap = {
  gate_info: PlaneUpIcon,
  immigration_wait_display: ClockIcon,
  security_wait_display: HourglassIcon,
  gate_distance: FootprintsIcon,
  checkin_close: UserCheckIcon,
  status: CalendarIcon,
  baggage_info: LuggageIcon,
  oversized_belt: ScalingIcon,
};

const flightInfo = (key, value, type) => {
  const IconComponent = iconsMap[key];

  let label = "";
  let text = "";
  let boldText = "";

  switch (key) {
    case "status":
      label = "FLIGHT INFO";
      text = `${value || ""}`;
      boldText = null;
      break;

    case "gate_info":
      label = "GATE INFO";
      text = `Time until boarding:`;
      boldText = `${value || ""}`;
      break;

    case "immigration_wait_display":
      label = `${
        type === "departure" ? "EMIGRATION" : "IMMIGRATION"
      } QUEUE TIME`;
      text = `Waiting time:`;
      boldText = `${value || ""}`;
      break;

    case "security_wait_display":
      label = "SECURITY QUEUE TIME";
      text = `Waiting time:`;
      boldText = `${value || ""}`;
      break;

    case "gate_distance":
      label = "TIME TO GATE";
      text = `After security, the walking time to this gate is:`;
      boldText = `${value || ""}`;
      break;

    case "checkin_close":
      label = "CHECK-IN DESK";
      text = `Closing at:`;
      boldText = `${DateTime.fromISO(value).toFormat("HH:mm")}`;
      break;

    case "oversized_belt":
      label = "BAGGAGE INFO";
      text = `Oversized/Fragile baggage at belt `;
      boldText = `${value}`;
      break;

    //TODO Mocked Data below
    case "baggage_info":
      label = "BAGGAGE INFO";
      text = `MOCKED Belt 1 opens at:`;
      boldText = `09:40`;
      break;

    default:
      break;
  }

  return { label, text, boldText, IconComponent };
};

const MessageBoxHeaderStyled = styled.div`
  border-top: ${(props) => props.theme.messageBox.borderWidth} solid
    ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
  gap: ${(props) => props.theme.spaces.m};
  display: flex;
  flex-direction: column;
`;

const GeneralInfo = ({ flight }) => {
  return (
    <>
      <MessageBoxHeaderStyled>
        {flight.info &&
          Object.entries(flight.info)?.map(([key, value]) => {
            const { label, text, boldText, IconComponent } = flightInfo(
              key,
              value,
              flight.type
            );

            if (!IconComponent) {
              return null;
            }

            if (!boldText && label !== "FLIGHT INFO" && text) {
              return null;
            }

            return (
              <Box key={key}>
                <Text mB="s" color="textSecondary" fontSize="s">
                  {label}
                </Text>
                <Box d="flex" alignItems="center">
                  <Box
                  // This Box is used to prevent Icon Shrinking
                  >
                    <IconComponent />
                  </Box>
                  <Text fontSize="m" mL="s">
                    {text}
                    {text && (
                      <Text as="span" fontWeight="bold">
                        &nbsp;{boldText}
                      </Text>
                    )}
                  </Text>
                </Box>
              </Box>
            );
          })}
      </MessageBoxHeaderStyled>
    </>
  );
};

export default GeneralInfo;
