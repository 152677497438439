import React from "react";
import { ReactComponent as SuccessCheckmarkIcon } from "assets/images/success.svg";
import { Box, Text } from "components/smalt";
import { useTheme } from "styled-components";

const Toast = ({ type, position, message }) => {
  const theme = useTheme();
  return (
    <Box
      backgroundColor="successBackground"
      d="flex"
      alignItems="center"
      borderRadius="base"
      gap="xs"
      minH="44px"
    >
      <SuccessCheckmarkIcon style={{ marginLeft: theme.spaces.s }} />
      <Text>{message}</Text>
    </Box>
  );
};

export default Toast;
