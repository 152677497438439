import React from "react";
import { ReactComponent as MetroIcon } from "assets/images/train-front-tunnel.svg";
import { Text } from "components";
import styled from "styled-components";

const MetroInfoStyled = styled.div`
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spaces.xs};
  background-color: ${(props) => props.theme.colors.success_1};
  /* prettier-ignore */
  padding: ${(props) => props.theme.spaces.s} ${(props) =>
    props.theme.spaces.base};
  border-radius: ${(props) => props.theme.borderRadius.base};
  margin-bottom: ${(props) => props.theme.spaces._base};
  margin-top: ${(props) => props.theme.spaces._s};
`;

const MetroInfoBox = ({ flightInfo }) => {
  const metroStart = flightInfo?.metro_start;
  const metroEnd = flightInfo?.metro_end;
  const metroLabel = flightInfo?.metro_label || "The metro operates between";
  const hasMetroInformation = metroStart && metroEnd;

  return (
    hasMetroInformation && (
      <MetroInfoStyled>
        <MetroIcon />
        <Text color="">
          {metroLabel}
          &nbsp;from
          <Text as="span" fontWeight="bold">
            &nbsp;{metroStart}&nbsp;
          </Text>
          to
          <Text as="span" fontWeight="bold">
            &nbsp;{metroEnd}
          </Text>
        </Text>
      </MetroInfoStyled>
    )
  );
};

export default MetroInfoBox;
