import React from "react";
import { Box, Icon, Text } from "components";
import { NOTIFICATION_CASES } from "configs/constants";
import styled from "styled-components";

const LearnMoreContent = ({ notificationType }) => {
  switch (notificationType) {
    case NOTIFICATION_CASES.IOS_VERSION_BELOW_16_4:
      return (
        <Text textAlign="justify" fontSize="m">
          Please update your operating system to version
          <Text as="span" fontWeight="semibold">
            &nbsp;16.4&nbsp;
          </Text>
          or above to receive push notifications. Older OS versions do not
          support this feature.
        </Text>
      );
    case NOTIFICATION_CASES.DEFAULT_BROWSER_NOT_SAFARI:
      return (
        <Text fontSize="m">
          Push notifications are only supported on
          <Text as="span" fontWeight="semibold">
            &nbsp;Safari&nbsp;
          </Text>
          browser. Please switch to Safari as your default browser to enable
          this feature.
        </Text>
      );
    case NOTIFICATION_CASES.APP_NOT_INSTALLED_AS_PWA:
      return (
        <>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;1.&nbsp;
            </Text>
            Tap the "Share" icon located within Safari.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;2.&nbsp;
            </Text>
            Select "Add to Home Screen" from the options.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;3.&nbsp;
            </Text>
            Tap "Add" or "Ok" to complete the process.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;4.&nbsp;
            </Text>
            Access Pocket Flights from home screen.
          </Text>
          <Text mB="m" fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;5.&nbsp;
            </Text>
            Enable notifications for your flight.
          </Text>
          <Text textAlign="justify" fontSize="m">
            Get instant notifications and stay well-informed about all aspects
            of your flight, including real-time updates on status changes, gate
            information, closing times, and other pertinent details.
          </Text>
        </>
      );
    case NOTIFICATION_CASES.IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA:
      return (
        <Box>
          <Text mB="m" textAlign="justify" fontSize="m">
            You can currently receive notifications in your browser, but these
            will not be delivered when you close the browser. To enjoy the full
            benefits and uninterrupted access to notifications, we recommend
            adding Pocket Flights to your home screen.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;1.&nbsp;
            </Text>
            Tap the three-dot menu icon from the top right corner of the
            browser.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;2.&nbsp;
            </Text>
            Select "Add to Home Screen" or "Install App".
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;3.&nbsp;
            </Text>
            Tap "Add" or "Install" to proceed.
          </Text>
          <Text fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;4.&nbsp;
            </Text>
            Access Pocket Flights from your home screen or from your app drawer.
          </Text>
          <Text mB="m" fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;5.&nbsp;
            </Text>
            Enable notifications for your flight.
          </Text>
          <Text textAlign="justify" fontSize="m">
            Get instant notifications and stay well-informed about all aspects
            of your flight, including real-time updates on status changes, gate
            information, closing times, and other pertinent details.
          </Text>
        </Box>
      );
    case NOTIFICATION_CASES.USER_IS_NOT_BROWSING_MOBILE:
      return (
        <Text textAlign="justify" fontSize="m">
          Get instant notifications and stay well-informed about all aspects of
          your flight, including real-time updates on status changes, gate
          information, closing times, and other pertinent details.
        </Text>
      );
    default:
      return null;
  }
};

const NotificationModalStyled = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundQuaternary};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  border-radius: ${(props) => props.theme.borderRadius.m};
  width: 100%;
`;

const NotificationInstructionsModalContent = ({
  notificationType,
  handleCloseNotificationsModal,
}) => {
  return (
    <NotificationModalStyled>
      <Box h="100%" d="flex" direction="column" alignItems="center">
        <Box
          d="flex"
          gap="s"
          justifyContent="center"
          alignItems="center"
          pV="m"
        >
          <Icon size="xl" name="Info" />
          <Text fontSize="l" fontWeight="semibold">
            Flight Notifications
          </Text>
        </Box>
        <Box pH="m">
          <LearnMoreContent notificationType={notificationType} />
        </Box>
        <Box mT="l" mB="base">
          <Text
            fontSize="l"
            color="primary"
            fontWeight="semibold"
            onClick={() =>
              handleCloseNotificationsModal((previousState) => !previousState)
            }
          >
            Close
          </Text>
        </Box>
      </Box>
    </NotificationModalStyled>
  );
};

export default NotificationInstructionsModalContent;
