import React from "react";
import { Box, Text } from "components";

const ViaInformation = ({ flights = [] }) => {
  if (!flights.length) {
    return null;
  }

  return (
    <Box d="flex" direction="column" gap="xs">
      {flights.length === 1 ? (
        <Text color="textPrimary" fontWeight="semibold" fontSize="l">
          1 stop
        </Text>
      ) : (
        <Text color="textPrimary" fontWeight="semibold" fontSize="l">
          {flights.length} stops
        </Text>
      )}
      <Box>
        {flights.map((viaFlight) => (
          <Text key={viaFlight.name} fontWeight="semibold">
            {viaFlight.name}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default ViaInformation;
