import { ReactComponent as NoFlightsImage } from "assets/images/no-flight.svg";
import { Box, Text } from "components";
import { useTheme } from "styled-components";

const EmptyList = ({ verticalOffset }) => {
  const { zIndex } = useTheme();
  return (
    <Box
      d="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="xl"
      mT="xxl"
      pH="xxl"
      position="absolute"
      top={`${verticalOffset}px`}
      style={{ zIndex: zIndex.above }}
    >
      <NoFlightsImage />
      <Text fontWeight="semibold" fontSize="m" textAlign="center">
        No flights match your search criteria. Please try using the flight
        number, city, or airline name
      </Text>
    </Box>
  );
};

export default EmptyList;
