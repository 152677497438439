import { ROUTES } from "../api";
import {
  addFlight,
  removeFlight,
  setHasNotificationsEnabled,
  setSubscription,
} from "../redux/slices/notifications.slice";
import store from "../redux/store";
import { urlB64ToUint8Array } from "../utils/urlB64ToUint8Array";
import { getClientCredentials } from "./auth.service";

export const askForPermission = async () => {
  let hasAllowedNotifications = false;
  await Notification.requestPermission(function (status) {
    if (status === "granted") {
      hasAllowedNotifications = true;
      store.dispatch(setHasNotificationsEnabled(true));
    } else {
      hasAllowedNotifications = false;
      store.dispatch(setHasNotificationsEnabled(false));
    }
  });
  return hasAllowedNotifications;
};

export const subscribeToNotificationsServer = async (flightId) => {
  const { hasNotificationsEnabled } = store.getState().notifications;

  if (!hasNotificationsEnabled) {
    let permissionsAllowed = await askForPermission();
    if (!permissionsAllowed) {
      return console.log("Permissions Denied");
    }
  }
  return await subscribeFlight(flightId);
};

export const subscribeFlight = async (flightId) => {
  const { publicKey } = store.getState().initialize;
  let subscription = null;

  const storeSubscription = store.getState().notifications.subscription;

  if (storeSubscription) {
    subscription = storeSubscription;
    return await sendFlightIdForSubscription(subscription, flightId);
  }

  subscription = await navigator.serviceWorker
    .getRegistration()
    .then((registration) => {
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(publicKey),
      });
    })
    .catch((error) => {
      console.log("Could not register. Error:" + error);
    });

  store.dispatch(setSubscription(subscription));

  return await sendFlightIdForSubscription(subscription, flightId);
};

const sendFlightIdForSubscription = async (subscription, flightId) => {
  const data = JSON.stringify({ subscription, flightId });

  const { clientId, clientSecret } = await getClientCredentials();
  try {
    await fetch(`${window._env_.REACT_APP_API_URL}${ROUTES.subscribe}`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "shared-key": window._env_.REACT_APP_SHARED_KEY,
        "client-id": clientId,
        "client-secret": clientSecret,
      },
    });
    store.dispatch(addFlight(flightId));
  } catch (error) {
    console.log("Could not subscribe flight: ", flightId, " Error: ", error);
  }
};

export const unsubscribeNotificationForFlight = async (flightId) => {
  const subscription = store.getState().notifications.subscription;
  const data = JSON.stringify({ subscription, flightId });
  const { clientId, clientSecret } = await getClientCredentials();
  try {
    await fetch(`${window._env_.REACT_APP_API_URL}${ROUTES.unsubscribe}`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "shared-key": window._env_.REACT_APP_SHARED_KEY,
        "client-id": clientId,
        "client-secret": clientSecret,
      },
    });
    store.dispatch(removeFlight(flightId));
  } catch (error) {
    console.log(
      "Error while trying to unsubscribes the flight: ",
      flightId,
      " Error: ",
      error
    );
  }
};
