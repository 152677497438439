import React from "react";
import { ReactComponent as StarIcon } from "assets/images/star.svg";
import { Box, Text } from "components";
import Toast from "components/Reusable/Toast";
import { ACTIVE_TAB, TOAST_CONFIG } from "configs/constants";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetFavoriteFlightsQuery } from "redux/queries/flights.query";
import {
  resetLastSelectedFlightItem,
  setActiveTab,
} from "redux/slices/flightsList.slice";
import {
  addFlightToFavorites,
  removeFlightFromFavorites,
} from "services/favorite-flights.service";
import { useTheme } from "styled-components";

const FavoriteFlights = ({ flight: detailsFlight }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { initialize } = useSelector((state) => state);
  const { uniqueDeviceIdentifier } = initialize;
  const { data: queryFavoriteFlights = [], refetch: refetchFavoriteFlights } =
    useGetFavoriteFlightsQuery(uniqueDeviceIdentifier, {
      refetchOnMountOrArgChange: true,
    });

  const isCurrentFlightFavorite = !!queryFavoriteFlights.find(
    (favoriteFlight) => favoriteFlight === detailsFlight.ref_id
  );

  const handleFavoriteFlightClick = () => {
    if (isCurrentFlightFavorite) {
      toast(
        <Toast
          type="success"
          position="top"
          message="This flight was removed from favorites"
        />,
        TOAST_CONFIG
      );
      removeFlightFromFavorites(detailsFlight.ref_id).then((res) => {
        refetchFavoriteFlights();
      });
    } else {
      dispatch(resetLastSelectedFlightItem());
      toast(
        <Toast
          type="success"
          position="top"
          message="This flight was added to favorites"
        />,
        TOAST_CONFIG
      );
      addFlightToFavorites(detailsFlight.ref_id).then(() => {
        refetchFavoriteFlights();
      });
      dispatch(setActiveTab(ACTIVE_TAB.FAVORITE));
    }
  };

  return (
    <Box mT="_xs" pH="m" w="100%" d="flex" direction="column" gap="xs">
      <Box w="100%" d="flex" alignItems="center" justifyContent="space-between">
        <Text fontSize="m">Add To Favorites</Text>
        <StarIcon
          onClick={handleFavoriteFlightClick}
          fill={isCurrentFlightFavorite ? theme.colors.primary : "none"}
          stroke={
            isCurrentFlightFavorite
              ? theme.colors.primary
              : theme.colors.textPrimary
          }
          width={24}
          height={24}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Text color="textSecondary">
        See this flight first when you open Pocket Flights.
      </Text>
    </Box>
  );
};

export default FavoriteFlights;
