import { useEffect } from "react";
import { ACTIVE_TAB, FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";
import usePushNotification from "hooks/usePushNotification";
import useRefetchInterval from "hooks/useRefetchInterval";
import useTabActive from "hooks/useTabActive";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useGetFavoriteFlightsQuery,
  useGetFlightsQuery,
} from "redux/queries/flights.query";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  flightsListSelector,
  setActiveTab,
  setSelectedFlightDetails,
  setVisitedForFirstTime,
} from "redux/slices/flightsList.slice";
import { checkAntiScraping } from "services/cookie.service";
import styled from "styled-components";
import { filterFlightsBySearch } from "utils/flightList.util";

import FlightsList from "./flightList/FlightsList";
import TicketScanner from "./flightList/TicketScanner";

const FlightsListPage = () => {
  useTrackPageAnalytics({
    name: "Flights List Page",
  });

  const [queryParams, setQueryParams] = useSearchParams();
  const terminalToFilter = queryParams.get("terminal");

  const dispatch = useDispatch();
  const { activeTab, searchKeyword, visitedForFirstTime } =
    useSelector(flightsListSelector);
  const isWebScraperDetected = !checkAntiScraping();

  const { data: settingsData = {}, refetch: refetchSettings } =
    useGetSettingsQuery(undefined, {
      refetchOnReconnect: true,
      pollingInterval: FLIGHTS_LIST_POLLING_INTERVAL,
      skip: isWebScraperDetected,
    });

  const {
    reloadIntervalSeconds,
    startSearchContainLength,
    terminals: terminalsDropdownOptionList = [],
  } = settingsData;

  let {
    data: flights = [],
    refetch: refetchFlights,
    isLoading: areFlightsLoading,
  } = useGetFlightsQuery(undefined, {
    skip: isWebScraperDetected,
    refetchOnMountOrArgChange: true,
  });

  const { initialize } = useSelector((state) => state);
  const { uniqueDeviceIdentifier } = initialize;

  const { data: queryFavoriteFlights = [] } = useGetFavoriteFlightsQuery(
    uniqueDeviceIdentifier,
    {
      skip: isWebScraperDetected || !flights.length,
      refetchOnMountOrArgChange: true,
    }
  );

  const favoriteFlights = flights.filter((flight) => {
    return queryFavoriteFlights.includes(flight.ref_id);
  });

  useEffect(() => {
    //Set active tab to Favorites if it's first time opening the app and persisted favorite flights were found
    if (visitedForFirstTime && favoriteFlights.length) {
      dispatch(setActiveTab(ACTIVE_TAB.FAVORITE));
      dispatch(setVisitedForFirstTime(false));
    }
    if (!favoriteFlights.length && activeTab === ACTIVE_TAB.FAVORITE) {
      dispatch(setActiveTab(ACTIVE_TAB.ALL));
    }
  }, [activeTab, dispatch, favoriteFlights, visitedForFirstTime]);

  //Reset refetch interval for flights
  useRefetchInterval({
    dispatch,
    setSelectedFlightDetails,
    reloadIntervalSeconds,
    refetchFlights,
  });

  //Refetch flights when user switches back to pocketFlights browser tab
  useTabActive(refetchFlights);

  const filteredFlights = filterFlightsBySearch(
    searchKeyword,
    { flights, favoriteFlights },
    { activeTab, terminalToFilter, startSearchContainLength }
  );

  const filteredFlightsWithoutTerminalFilterApplied = filterFlightsBySearch(
    searchKeyword,
    { flights, favoriteFlights: null },
    { activeTab, startSearchContainLength, terminalToFilter: null }
  );

  usePushNotification({
    refetchFlights,
    refetchSettings,
    dispatch,
    setSelectedFlightDetails,
    filteredFlights,
  });

  return (
    <FlightListStyled>
      <FlightsList
        terminalsDropdownOptionList={terminalsDropdownOptionList}
        flights={filteredFlights}
        showFavoriteTab={favoriteFlights.length}
        unfilteredFlights={filteredFlightsWithoutTerminalFilterApplied}
        hasSearched={!!searchKeyword}
        terminal={terminalToFilter}
        areFlightsLoading={areFlightsLoading}
        setQueryParams={setQueryParams}
      />
      <TicketScanner flights={flights} />
    </FlightListStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const FlightListStyled = styled.div`
  //Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  -webkit-overscroll-behavior-y: none;
  -webkit-overscroll-behavior-x: none;

  touch-action: pan-y;

  position: relative;
  overflow: scroll;
  max-height: 100svh; //svh - small view height (for phones)
`;

export default FlightsListPage;
